import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Home from './components/Home';
import Services from './components/Services';
import Gallery from './components/Gallery';
import Contact from './components/Contact';
import Footer from './components/Footer';
import Who from './components/Who';
import SocialContact from './components/SocialContact';
import ContactPage from './pages/ContactPage';
import FAQ from './components/FAQ';
import HowItWorks from './components/HowItWorks';
import HomeBackground from './assets/bg/home_bg_2.jpg'; // Asegúrate de que la ruta sea correcta
import AboutMePage from './pages/AboutMe';
import GalleryPage from './pages/Gallery';

const SharedBackgroundLayout: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <div className="relative">
    {/* Fondo compartido */}
    <div 
      className="absolute inset-0 bg-cover bg-center bg-no-repeat z-0 -mb-20"
      style={{ backgroundImage: `url(${HomeBackground})` }}
    ></div>
    {/* Overlay semi-transparente (opcional) */}
    <div className="absolute inset-0 bg-white bg-opacity-80 z-5"></div>
    {/* Contenido */}
    <div className="relative z-10">
      {children}
    </div>
  </div>
);

const MainLayout: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <>
    <Header />
    <div className="relative z-10 bg-white min-h-screen flex flex-col">
      <main className="flex-grow">
        {children}
      </main>
      <SocialContact />
      <Footer />
    </div>
  </>
);

const ContactLayout: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <div className="relative z-10 bg-white min-h-screen flex flex-col">
    <main className="flex-grow">
      {children}
    </main>
    <Footer />
  </div>
);

const App: React.FC = () => {
  return (
    <Router>
      <div className="w-full overflow-x-hidden">
        <Routes>
          <Route path="/" element={
            <MainLayout>
              <SharedBackgroundLayout>
                <Home />
                <HowItWorks />
                <Services />
              </SharedBackgroundLayout>
              <Gallery />
              <Contact />
              <FAQ />
              <Who />
            </MainLayout>
          } />
          <Route path="/galeria" element={
            // <MainLayout>
              <GalleryPage />
            // </MainLayout> 
          } />
          <Route path="/contacto" element={
            <ContactLayout>
              <ContactPage />
            </ContactLayout>
          } />
          <Route path="/about-me" element={
              <AboutMePage />
          } />
        </Routes>
      </div>
    </Router>
  );
};


export default App;