import React from 'react';
import { Link } from 'react-router-dom';
import { Instagram, MessageCircle } from 'lucide-react';

const SocialContact: React.FC = () => {
  const phoneNumber = '34655691558'; 
  const whatsappLink = `https://wa.me/${phoneNumber}`;

  return (
    <section className="bg-black text-white py-8">
      <div className="container mx-auto px-4">
        <div className="flex flex-col md:flex-row justify-between items-center">
          <div className="mb-4 md:mb-0">
            <Link to="/contacto" className="font-upperBold text-sm uppercase tracking-wider mb-2 hover:text-gray-300 transition duration-300">Contacta con nosotros</Link>
            <p className="text-sm">Síguenos en redes sociales o contáctanos directamente</p>
          </div>
          <div className="flex space-x-6">
            <a href="https://www.instagram.com/ivetcasas" target="_blank" rel="noopener noreferrer" className="hover:text-gray-300 transition duration-300">
              <Instagram size={24} />
            </a>
            <a href={whatsappLink} target="_blank" rel="noopener noreferrer" className="hover:text-gray-300 transition duration-300">
              <MessageCircle size={24} />
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SocialContact;