import React, { useState } from 'react';
import weddingImage from '../assets/home.jpg';


const Home: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openTypeform = () => {
    setIsModalOpen(true);
  };

  return (
    <section id="home" className="relative">

      {/* Contenido */}
      <div className="mt-20 text-justify relative z-10 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="flex flex-col"> 
          <h1 className="text-4xl md:text-5xl lg:text-6xl text-gray-800 mb-4 font-upperBold">
            Despedidas Excepcionales,
          </h1>
          <div className="flex flex-col md:flex-row gap-8"> 
            <div className="md:w-1/3 overflow-hidden">
              <img 
                src={weddingImage} 
                alt="Despedida de soltera" 
                className="w-full h-2/3 object-cover object-bottom rounded-lg"
              />
            </div>
            <div className="md:w-2/3 flex flex-col">
              <h1 className="text-4xl md:text-5xl lg:text-6xl text-gray-800 mb-8 font-upperBold">
                Sin Complicaciones
              </h1>
              <h2 className="text-2xl font-semibold text-gray-800 mb-4">
                Encárgate únicamente de disfrutar.
              </h2>
              <p className="text-gray-600 mb-4">
                ¿Sueñas con una despedida de soltera inolvidable pero te abruma la organización? En [Nombre de tu Empresa], nos encargamos de todo. Transformamos tus ideas en experiencias perfectas, personalizadas hasta el último detalle, sin que tengas que preocuparte por la logística o las reservas.
              </p>
              <p className="text-gray-600 mb-6">
                Nuestro equipo de expertos se ocupa de cada aspecto, desde la planificación hasta la ejecución, para que tú y tus amigas solo tengáis que disfrutar. Ya sea una escapada relajante, una aventura emocionante o una fiesta espectacular, creamos la despedida de tus sueños. Con [Nombre de tu Empresa], tu única preocupación será decidir qué ponerte.
              </p>
              <button
                onClick={openTypeform}
                className="bg-white border border-black text-black px-6 py-3 rounded-full text-sm uppercase tracking-wider hover:bg-black hover:text-white transition duration-300 self-start"
              >
                Descubre nuestros servicios
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Modal (sin cambios) */}
      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="relative w-full h-full md:w-11/12 md:h-5/6 lg:w-10/12 lg:h-5/6 xl:w-3/4 xl:h-5/6">
            <button
              onClick={() => setIsModalOpen(false)}
              className="absolute top-2 right-2 text-white text-3xl z-10 bg-black bg-opacity-50 rounded-full w-10 h-10 flex items-center justify-center hover:bg-opacity-70 transition-all"
            >
              &times;
            </button>
            <iframe
              src="https://9j3021gtlse.typeform.com/to/n2kGlFAy"
              className="w-full h-full"
              frameBorder="0"
              allow="camera; microphone; autoplay; encrypted-media;"
            ></iframe>
          </div>
        </div>
      )}
    </section>
  );
};

export default Home;