import React from 'react';
import { Link } from 'react-router-dom';
import { Instagram, MessageCircle, Mail, ArrowLeft } from 'lucide-react';

const ContactPage: React.FC = () => {
  const phoneNumber = '34655691558';
  const whatsappLink = `https://wa.me/${phoneNumber}`;

  return (
    <div className="bg-cream bg-  py-20 px-4">
      <div className="max-w-4xl mx-auto bg-white shadow-xl rounded-lg p-8">

        
        <h1 className="text-4xl font-upperBold text-center mb-12">Contacta con Nosotros</h1>
        
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div>
            <h2 className="text-2xl font-upperBold mb-4">Información de Contacto</h2>
            <p className="mb-4">Estamos aquí para ayudarte a planificar tu evento perfecto. No dudes en contactarnos por cualquier medio que prefieras.</p>
            
            <div className="space-y-4">
              <a href={whatsappLink} target="_blank" rel="noopener noreferrer" className="flex items-center space-x-3 text-gray-600 hover:text-black">
                <MessageCircle size={24} />
                <span>WhatsApp</span>
              </a>
              <a href="https://www.instagram.com/ivetcasas" target="_blank" rel="noopener noreferrer" className="flex items-center space-x-3 text-gray-600 hover:text-black">
                <Instagram size={24} />
                <span>Instagram</span>
              </a>
              <a href="mailto:info@ivetcasas.com" className="flex items-center space-x-3 text-gray-600 hover:text-black">
                <Mail size={24} />
                <span>info@ivetcasas.com</span>
              </a>
            </div>
          </div>
          
          <div>
            <h2 className="text-2xl font-upperBold mb-4">Envíanos un Mensaje</h2>
            <form className="space-y-4">
              <div>
                <label htmlFor="name" className="block text-sm font-medium text-gray-700 mb-1">Nombre</label>
                <input type="text" id="name" name="name" className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-black" required />
              </div>
              <div>
                <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">Email</label>
                <input type="email" id="email" name="email" className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-black" required />
              </div>
              <div>
                <label htmlFor="message" className="block text-sm font-medium text-gray-700 mb-1">Mensaje</label>
                <textarea id="message" name="message" rows={4} className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-black" required></textarea>
              </div>
              <button type="submit" className="bg-black text-white px-6 py-2 rounded-md hover:bg-gray-800 transition duration-300">Enviar Mensaje</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactPage;