import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Menu } from 'lucide-react';

const Navigation: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);

  const getTransformStyle = (finalX: number, finalY: number, scaleFactor: number) => {
    return {
      transform: `
        translateX(${finalX}%)
        translateY(${finalY}px)
        scale(${1 - scaleFactor * 1})
      `,
      transition: 'transform 0.3s ease-out',
    };
  };

  const getIvetStyle = () => {
    const finalX = 32.5;
    const finalY = -183;
    const scaleFactor = 0.76;

    return getTransformStyle(finalX, finalY, scaleFactor);
  };

  const getCasasStyle = () => {
    const finalX = -25.7;
    const finalY = -235;
    const scaleFactor = 0.76;

    return getTransformStyle(finalX, finalY, scaleFactor);
  };

  const getBacheloretteStyle = () => {
    const finalX = 0;
    const finalY = 70;
    const scaleFactor = 0;

    return getTransformStyle(finalX, finalY, scaleFactor);
  };

  return (
    <>
      <nav className="fixed top-0 left-0 right-0 z-40 bg-white shadow-md py-10">
        <div className="container mx-auto px-4 flex justify-between items-center relative">
          <button onClick={() => setIsOpen(!isOpen)} className="md:hidden">
            <Menu className="text-black" />
          </button>
          <div className={`${isOpen ? 'block' : 'hidden'} uppercase font-upperBold md:block md:flex md:items-center md:space-x-6 bg-white p-4 md:p-0`}>
            <Link to="/" className="block md:inline hover:text-gray-600 text-xs tracking-wider tracking-[0.5em] mb-2 md:mb-0 text-black">Inicio</Link>
            <Link to="/#servicios" className="block md:inline hover:text-gray-600 text-xs tracking-wider mb-2 md:mb-0 text-black">Servicios</Link>
            <Link to="/#galeria" className="block md:inline hover:text-gray-600 text-xs tracking-wider mb-2 md:mb-0 text-black">Galería</Link>
            <Link to="/contacto/" className="block md:inline hover:text-gray-600 text-xs tracking-wider text-black">Contacto</Link>
          </div>
        </div>
      </nav>

      <div className="fixed top-0 left-0 w-full h-screen pointer-events-none z-50">
        <div className="absolute inset-0 flex flex-col items-center justify-start">
          <h2 className="font-upperBold text-xs uppercase tracking-[0.3em] md:tracking-[0.6em] text-black mb-4" style={getBacheloretteStyle()}>
            Bachelorette Party Planner
          </h2>
          <h1 className="font-light text-6xl md:text-8xl lg:text-[12rem] xl:text-[14rem] text-black leading-none">
            <span 
              className="block origin-bottom-left transition-transform duration-300 ease-out"
              style={getIvetStyle()}
            >
              Ivet
            </span> 
            <span 
              className="block ml-auto w-max origin-top-right transition-transform duration-300 ease-out"
              style={getCasasStyle()}
            >
              Casas
            </span>
          </h1>
        </div>
      </div>
    </>
  );
};

export default Navigation;