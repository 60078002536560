import React from 'react';
import StaticNavigation from '../components/StaticNavigation';
import { Link } from 'react-router-dom';

const AboutMePage: React.FC = () => {
    return (
        <div className="flex flex-col min-h-screen">
            <StaticNavigation />
            <section className="flex-grow pt-16 overflow-hidden bg-gray-50 md:pt-20 sm:pt-24 2xl:pt-28">
                <div className="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
                    <div className="grid items-center grid-cols-1 md:grid-cols-2">
                        <div>
                            <h2 className="text-3xl font-bold leading-tight text-black sm:text-4xl lg:text-5xl">Ivet Casas
                            </h2>
                            <p className="max-w-lg mt-3 text-xl leading-relaxed text-gray-600 md:mt-8">
                            Soy más que una organizadora de eventos; soy una creadora de recuerdos. Mi enfoque en la personalización total me permite diseñar experiencias que resuenan con la esencia de cada novia. Con años de experiencia y una red de contactos exclusivos, transformo tus ideas más audaces en realidades deslumbrantes. Mi objetivo es simple: hacer que tu última celebración como soltera sea tan especial como el gran día que le sigue.
                            </p>

                            <Link to="/contacto" className="inline-block mt-8 bg-green-700 hover:bg-green-900 text-white font-bold py-2 px-4 rounded-full">
                                Contacto
                            </Link>
                        </div>

                        <div className="relative">
                            <img className="absolute inset-x-0 bottom-0 -mb-48 -translate-x-1/2 left-1/2" src="https://cdn.rareblocks.xyz/collection/celebration/images/team/1/blob-shape.svg" alt="" />

                            <img className="relative w-full xl:max-w-lg xl:mx-auto 2xl:origin-bottom 2xl:scale-110" src="https://cdn.rareblocks.xyz/collection/celebration/images/team/1/business-woman.png" alt="" />
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default AboutMePage;