import React from 'react';
import weddingImage from '../assets/bride_bg.jpg';

const BackgroundImage: React.FC = () => (
  <div className="absolute inset-0 z-0">
    <img src={weddingImage} alt="Background" className="w-full h-full object-cover" />
    <div className="absolute inset-0 bg-white opacity-50"></div>
  </div>
);

const Gallery: React.FC = () => {
  return (
    <section id="galeria" className="bg-white shadow-md rounded-lg p-8 mb-8 h-96 flex flex-col justify-between relative">
      <BackgroundImage />
      <div className="flex flex-col justify-center flex-grow relative z-10">
        <h2 className="text-2xl font-upperBold text-primary mb-6 text-center">Portfolio</h2>
        <h2 className="text-4xl font-upperBold text-primary mb-6 text-center">Galeria de Momentos</h2>
      </div>
      <div className="flex justify-center mb-4 relative z-10">
        <button className="bg-black text-white py-2 px-4">
          VER TODOS
        </button>
      </div>
    </section>
  );
};

export default Gallery;