import React from 'react';


const Who: React.FC = () => {
  return (
    <section id="quienes-somos" className="bg-cream py-20 px-8">
      <div className="max-w-6xl mx-auto">
        <h2 className="text-4xl font-upperBold text-center mb-16">Quienes Somos</h2>
        <div className="flex flex-col md:flex-row items-center md:items-start gap-8">
          <div className="flex-shrink-0">
            <img 
              src={require('../assets/woman.jpg')}
              alt="Imagen de perfil" 
              className="w-64 h-64 object-cover rounded-full"
            />
          </div>
          <div className="flex flex-col">
            <h3 className="text-3xl font-light mb-4">Ivet Casas</h3>
            <p className="font-regular text-lg leading-relaxed">
            Soy más que una organizadora de eventos; soy una creadora de recuerdos. 
            Mi enfoque en la <span className="font-upperBold">personalización total</span> me permite diseñar experiencias 
            que resuenan con la esencia de cada novia. 

            Con años de experiencia y una red de contactos exclusivos, 
            transformo tus ideas más audaces en realidades deslumbrantes. 
            Mi objetivo es simple: hacer que tu última celebración como 
            soltera sea tan especial como el gran día que le sigue.

            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Who;