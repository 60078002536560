import React from 'react';

interface ServiceCardProps {
  title: string;
  description: string;
  color: 'indigo' | 'purple' | 'blue' | 'yellow' | 'green' | 'beige';
  onClick?: () => void;
}


const ServiceCard: React.FC<ServiceCardProps> = ({ title, description, color, onClick }) => {

  return (
    <div 
      id="servicios"
      className="w-full sm:w-1/2 mb-10 sm:mb-0 transform transition duration-300 hover:scale-105 cursor-pointer"
      onClick={onClick}
    >
      <div className="relative h-full ml-0 mr-0 sm:mr-10">
        <div className={`relative h-full p-5 bg-white border-2 border-custom-beige rounded-lg shadow-xl`}>
          <div className="flex items-center -mt-1">
            <h3 className="my-2 ml-3 text-lg font-bold text-gray-800">{title}</h3>
          </div>

          <p className="mb-2 text-gray-600">{description}</p>
        </div>
      </div>
    </div>
  );
};

const Services: React.FC = () => {
  return (
    <div className="container relative flex flex-col justify-between h-full max-w-6xl px-10 mx-auto xl:px-0 py-20">
      <h2 className="mb-1 text-3xl font-upperBold leading-tight text-gray-900">Services</h2>
      <p className="mb-12 text-lg text-gray-500">Here are a few of the awesome Services we provide.</p>
      <div className="w-full">
        <div className="flex flex-col w-full mb-10 sm:flex-row">
          <ServiceCard
            title="DAPP Development"
            description="A decentralized application (dapp) is an application built on a decentralized network that combines a smart contract and a frontend user interface."
            color="beige"
          />
          <ServiceCard
            title="Web 3.0 Development"
            description="Web 3.0 is the third generation of Internet services that will focus on understanding and analyzing data to provide a semantic web."
            color="beige"
          />
        </div>
        <div className="flex flex-col w-full sm:flex-row">
          <ServiceCard
            title="Project Audit"
            description="A Project Audit is a formal review of a project, which is intended to assess the extent up to which project management standards are being upheld."
            color="beige"
          />
          <ServiceCard
            title="Hacking / RE"
            description="A security hacker is someone who explores methods for breaching defenses and exploiting weaknesses in a computer system or network."
            color="beige"
          />
          <ServiceCard
            title="Hola que pasa"
            description="Bot development frameworks were created as advanced software tools that eliminate a large amount of manual work and accelerate the development process."
            color="beige"
          />
        </div>
      </div>
    </div>
  );
};

export default Services;