import React from 'react';
import LightbulbIcon from '../assets/icons/lightbulb.png';
import CalendarIcon from '../assets/icons/calendar.png';
import CelebrationIcon from '../assets/icons/celebration.png';

interface Step {
  icon: React.ReactNode;
  title: string;
  description: string;
}

const Lightbulb: React.FC<{ className?: string }> = ({ className }) => (
  <img src={LightbulbIcon} alt="Lightbulb" className={className} />
);

const Calendar: React.FC<{ className?: string }> = ({ className }) => (
  <img src={CalendarIcon} alt="Calendar" className={className} />
);

const Celebration: React.FC<{ className?: string }> = ({ className }) => (
  <img src={CelebrationIcon} alt="Celebration" className={className} />
);

const steps: Step[] = [
  {
    icon: <Lightbulb className="w-8 h-8 text-gray-500" />,
    title: "Inspiración",
    description: "Compartimos ideas y capturamos tu visión para la despedida perfecta."
  },
  {
    icon: <Calendar className="w-8 h-8 text-gray-500" />,
    title: "Creación",
    description: "Diseñamos un itinerario personalizado y nos encargamos de todos los detalles, sin que te preocupes de nada."
  },
  {
    icon: <Celebration className="w-8 h-8 text-gray-500" />,
    title: "Celebración",
    description: "Tú y tus amigas viven una experiencia inolvidable sin preocupaciones."
  }
];

const StepsComponent: React.FC = () => {
  return (
    <section className="relative overflow-hidden bg-transparent">
      <div className="mt-2 md:mt-0 py-12 pb-6 sm:py-16 lg:pb-24 overflow-hidden">
        <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8 relative">
          <div className="relative mt-12 lg:mt-20">
            <div className="absolute inset-x-0 hidden xl:px-44 top-2 md:block md:px-20 lg:px-28">
              <svg className="w-full" xmlns="http://www.w3.org/2000/svg" width="875" height="48" viewBox="0 0 875 48" fill="none">
                <path
                  d="M2 29C20.2154 33.6961 38.9915 35.1324 57.6111 37.5555C80.2065 40.496 102.791 43.3231 125.556 44.5555C163.184 46.5927 201.26 45 238.944 45C312.75 45 385.368 30.7371 458.278 20.6666C495.231 15.5627 532.399 11.6429 569.278 6.11109C589.515 3.07551 609.767 2.09927 630.222 1.99998C655.606 1.87676 681.208 1.11809 706.556 2.44442C739.552 4.17096 772.539 6.75565 805.222 11.5C828 14.8064 850.34 20.2233 873 24"
                  stroke="#D4D4D8" strokeWidth="3" strokeLinecap="round" strokeDasharray="1 12"
                />
              </svg>
            </div>
            <div className="relative grid grid-cols-1 text-center gap-y-8 sm:gap-y-10 md:gap-y-12 md:grid-cols-3 gap-x-12">
              {steps.map((step, index) => (
                <div key={index} className="bg-white bg-opacity-0 p-6 rounded-lg">
                  <div className="flex items-center justify-center w-16 h-16 mx-auto bg-white border-2 border-gray rounded-full shadow -mt-5">
                    {step.icon}
                  </div>
                  <h3 className="mt-4 sm:mt-6 text-2xl font-upperBold leading-tight text-gray-900 md:mt-10">
                    {step.title}
                  </h3>
                  <p className="mt-3 sm:mt-4 font-upperBold text-base text-gray-600">
                    {step.description}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default StepsComponent;
// import React from 'react';

// interface Step {
//   number: number;
//   title: string;
//   description: string;
// }

// const HowItWorks: React.FC = () => {
//   const steps: Step[] = [
//     {
//       number: 1,
//       title: "Create your Account",
//       description: "Condimentum vit pellemsque habitant morbi at molestie.",
//     },
//     {
//       number: 2,
//       title: "Setup your Account",
//       description: "Condimentum vit pellemsque habitant morbi at molestie.",
//     },
//     {
//       number: 3,
//       title: "Start creating with Horizon",
//       description: "Condimentum vit pellemsque habitant morbi at molestie.",
//     },
//   ];

//   const ArrowIcon: React.FC = () => (
//     <svg
//       xmlns="http://www.w3.org/2000/svg"
//       width="43"
//       height="42"
//       viewBox="0 0 43 42"
//       fill="none"
//       className="rotate-90 lg:rotate-0"
//     >
//       <g clipPath="url(#clip0_3346_6663)">
//         <path
//           d="M16.9242 11.7425C16.2417 12.425 16.2417 13.5275 16.9242 14.21L23.7142 21L16.9242 27.79C16.2417 28.4725 16.2417 29.575 16.9242 30.2575C17.6067 30.94 18.7092 30.94 19.3917 30.2575L27.4242 22.225C28.1067 21.5425 28.1067 20.44 27.4242 19.7575L19.3917 11.725C18.7267 11.06 17.6067 11.06 16.9242 11.7425Z"
//           fill="#68769F"
//         />
//       </g>
//       <defs>
//         <clipPath id="clip0_3346_6663">
//           <rect
//             width="42"
//             height="42"
//             fill="white"
//             transform="translate(0.666748)"
//           />
//         </clipPath>
//       </defs>
//     </svg>
//   );

//   return (
//     <div className="w-full">
//       <div className="container mx-auto my-32 flex flex-col items-center gap-16">
//         <div className="flex flex-col gap-16">
//           <div className="flex flex-col gap-2 text-center">
//             <h2 className="mb-2 text-3xl font-extrabold leading-tight text-dark-grey-900 lg:text-4xl">
//               How Horizon works?
//             </h2>
//             <p className="text-base font-medium leading-7 text-dark-grey-600">
//               Tellus rutrum tellus pellentesque eu tincidunt tortor condimentum.
//             </p>
//           </div>
//         </div>
//         <div className="flex w-full flex-col items-center justify-between gap-y-10 lg:flex-row lg:gap-x-8 lg:gap-y-0 xl:gap-x-10">
//           {steps.map((step, index) => (
//             <React.Fragment key={step.number}>
//               <div className="flex items-start gap-4">
//                 <div
//                   className={`flex h-12 w-12 shrink-0 items-center justify-center rounded-full border-2 border-solid border-purple-blue-500 bg-transparent`
                
//                   }
//                 >
//                   <span className={`text-base font-bold leading-7text-black`
//                   }>{step.number}</span>
//                 </div>
//                 <div className="flex flex-col">
//                   <h3 className="mb-2 text-base font-bold leading-tight text-dark-grey-900">
//                     {step.title}
//                   </h3>
//                   <p className="text-base font-medium leading-7 text-dark-grey-600">
//                     {step.description}
//                   </p>
//                 </div>
//               </div>
//               {index < steps.length - 1 && <ArrowIcon />}
//             </React.Fragment>
//           ))}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default HowItWorks;