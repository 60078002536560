import React, { useState, useEffect, useCallback } from 'react';
import backgroundImage from '../assets/wedding.jpg';
import Navigation from './Navigation';
import ArrowDown from './ArrowDown';


// Hook para obtener las dimensiones de la ventana
const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions({
        width: window.innerWidth,
        height: window.innerHeight
      });
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
};

// Hook para detectar si el dispositivo es móvil
const useIsMobile = () => {
  const { width } = useWindowDimensions();
  return width <= 768;
};

const Header: React.FC = () => {
  const [textScrollProgress, setTextScrollProgress] = useState(0);
  const [parallaxScrollProgress, setParallaxScrollProgress] = useState(0);
  const isMobile = useIsMobile();
  const { height } = useWindowDimensions();

  const handleScroll = useCallback(() => {
    const scrollPosition = window.pageYOffset;
    const maxTextScroll = height * 1.1; // Reducido para completar la animación más rápido
    const maxParallaxScroll = height * 1.2; // Ajustado para un scroll más corto

    const textProgress = Math.min(scrollPosition / maxTextScroll, 0.8);
    const parallaxProgress = Math.min(scrollPosition / maxParallaxScroll, 1);

    setTextScrollProgress(textProgress);
    setParallaxScrollProgress(parallaxProgress);
  }, [height]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  const getTransformStyle = (initialX: number, initialY: number, finalX: number, finalY: number, scaleFactor: number) => {
    const deltaX = finalX - initialX;
    const deltaY = finalY - initialY;
    
    return {
      transform: `
        translateX(${initialX + deltaX * textScrollProgress}%)
        translateY(${initialY + deltaY * textScrollProgress}px)
        scale(${1 - scaleFactor * textScrollProgress})
      `,
      transition: 'transform 0.3s ease-out',
    };
  };

  const getIvetStyle = () => {
    const initialX = isMobile ? 10 : -25;
    const initialY = isMobile ? 0 : 150;
    const finalX = isMobile ? 17 : 47;
    const finalY = isMobile ? -430 : -265;
    const scaleFactor = isMobile ? 0.2 : 0.95;

    return getTransformStyle(initialX, initialY, finalX, finalY, scaleFactor);
  };

  const getCasasStyle = () => {
    const initialX = isMobile ? -15 : 40;
    const initialY = isMobile ? 0 : 180;
    const finalX = isMobile ? -45 : -42;
    const finalY = isMobile ? -510 : -338;
    const scaleFactor = isMobile ? 0.2 : 0.95;

    return getTransformStyle(initialX, initialY, finalX, finalY, scaleFactor);
  };

  const getBacheloretteStyle = () => {
    const initialX = isMobile ? -15 : 0;
    const initialY = isMobile ? 0 : 120;
    const finalX = isMobile ? -45 : 0;
    const finalY = isMobile ? -510 : 59;
    const scaleFactor = isMobile ? 0 : 0;

    return getTransformStyle(initialX, initialY, finalX, finalY, scaleFactor);
  };

  const getParallaxStyle = () => {
    return {
      transform: `translateY(${parallaxScrollProgress * -40}%)`, // Reducido de -50% a -10%
      transition: 'transform 0.3s ease-out',
    };
  };

  const handleServicesClick = () => {
    const servicesSection = document.getElementById('servicios');
    if (servicesSection) {
      const yOffset = -235; // Ajusta este valor según la distancia deseada desde la parte superior
      const y = servicesSection.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: 'smooth' });
    }
  };

  return (
    <>
      {/* Contenedor principal con efecto parallax */}
      <div className="relative w-full" style={{ height: '100vh' }}> {/* Reducido de 200vh a 120vh */}
        {/* Sección fija del fondo */}
        <div className="fixed top-0 left-0 w-full h-screen">
          <div className="absolute inset-0" style={getParallaxStyle()}>
            <img src={backgroundImage} alt="Background" className="w-full h-full object-cover" />
            <div className="absolute inset-0 bg-gradient-to-b from-white via-white to-transparent opacity-50"></div>
          </div>
          <div className="absolute inset-0 flex flex-col justify-between p-4">
        <div className="text-center mt-20">
        </div>
        
        <div className="flex justify-center mb-24">
          <button onClick={handleServicesClick} className="-bottom-80 border border-black text-black px-6 md:px-10 py-2 md:py-3 text-xs uppercase tracking-[0.2em] hover:bg-black hover:text-white transition duration-300 relative z-40">
            Servicios
            </button>
          </div>
          
              
              <div className="font-upperBold flex justify-between">
                <span className="text-[10px] md:text-xs uppercase tracking-[0.1em] md:tracking-[0.2em] text-black">Experiencias Únicas</span>
                <span className="text-[10px] md:text-xs uppercase tracking-[0.1em] md:tracking-[0.2em] text-black">Momentos Inolvidables</span>
              </div>
            </div>
          </div>
          
        {/* Texto superpuesto con animación */}
        <div className="fixed top-0 left-0 w-full h-screen pointer-events-none z-50">
          <div className="absolute inset-0 flex flex-col items-center justify-start">
            <h2 className="font-upperBold text-xs uppercase tracking-[0.3em] md:tracking-[0.6em] text-black mb-4" style={getBacheloretteStyle()}>
                Bachelorette Party Planner
            </h2>
            <h1 className="font-light text-6xl md:text-8xl lg:text-[12rem] xl:text-[14rem] text-black leading-none">
              <span 
                className="block origin-bottom-left transition-transform duration-300 ease-out"
                style={getIvetStyle()}
              >
                Ivet
              </span> 
              <span 
                className="block ml-auto w-max origin-top-right transition-transform duration-300 ease-out"
                style={getCasasStyle()}
              >
                Casas
              </span>
            </h1>
          </div>
        </div>
      </div>
      
      <ArrowDown />
      <Navigation />
    </>
  );
};

export default Header;