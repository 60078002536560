import React from 'react';

const Footer: React.FC = () => {
  return (
    <footer className="bg-secondary relative z-10">
      <div className="max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:px-8">
        <p className="text-center text-gray-700">
          &copy; {new Date().getFullYear()} Tu Empresa de Despedidas de Soltera. Todos los derechos reservados.
        </p>
      </div>
    </footer>
  );
};

export default Footer;