import React, { useState } from 'react';
import { ChevronDown } from 'lucide-react';

const BouncingArrow = ({ bottomMargin = 20, size = 60, scrollOffset = 96 }) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleClick = () => {
    const serviciosSection = document.getElementById('home');
    if (serviciosSection) {
      const yOffset = -scrollOffset; 
      const y = serviciosSection.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: 'smooth' });
    }
  };

  return (
    <div 
      className="fixed left-1/2 transform -translate-x-1/2 cursor-pointer" 
      style={{ bottom: `${bottomMargin}px` }}
      onClick={handleClick}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div 
        className={`relative flex justify-center items-center border border-black rounded-full transition-colors duration-300 ${
          isHovered ? 'bg-white bg-opacity-50' : 'bg-transparent'
        }`}
        style={{ width: `${size}px`, height: `${size}px` }}
      >
        <div className="absolute" style={{ top: '30%' }}>
          <ChevronDown 
            size={size / 2}
            className={`animate-bounce transition-colors duration-300 ${
              isHovered ? 'text-gray-800' : 'text-black'
            }`}
          />
        </div>
      </div>
    </div>
  );
};

export default BouncingArrow;