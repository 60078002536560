import React from 'react';

interface ImageData {
  src: string;
  alt: string;
}

const imageData: ImageData[][] = [
  [
    { src: "/api/placeholder/400/300", alt: "Kashi Vishwanath Temple" },
    { src: "/api/placeholder/400/300", alt: "Temple image 1" },
    { src: "/api/placeholder/400/300", alt: "Temple image 2" },
  ],
  [
    { src: "/api/placeholder/400/300", alt: "Temple image 3" },
    { src: "/api/placeholder/400/300", alt: "Temple image 4" },
    { src: "/api/placeholder/400/300", alt: "Golden Temple" },
  ],
  [
    { src: "/api/placeholder/400/300", alt: "Shri Kashi Vishwanath Temple" },
    { src: "/api/placeholder/400/300", alt: "Kashi Vishwanath Mandir" },
    { src: "/api/placeholder/400/300", alt: "Kashi Vishwanath Dham" },
  ],
  [
    { src: "/api/placeholder/400/300", alt: "Kashi Vishwanath Mandir Varanasi" },
    { src: "/api/placeholder/400/300", alt: "Ahilya Ghat by the Ganges" },
    { src: "/api/placeholder/400/300", alt: "Chet Singh Ghat in Varanasi" },
  ],
];

const ImageGallery: React.FC = () => {
  return (
    <div className="container mx-auto p-4">
      <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
        {imageData.map((column, columnIndex) => (
          <div key={columnIndex} className="grid gap-4">
            {column.map((image, imageIndex) => (
              <div key={imageIndex}>
                <img
                  className="h-auto max-w-full rounded-lg"
                  src={image.src}
                  alt={image.alt}
                />
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ImageGallery;